import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { WhiteButton } from "../components/buttons"

const NotFoundPage = () => (
  <Layout>
    <div className="flex flex-col items-center">
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <div className="w-full flex justify-end pt-4 pr-2">
        <WhiteButton to="/" text="Back" />
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
