import React, { useState } from "react"
import PropTypes from "prop-types"

import { NavIcon } from "./icons"
import { GithubButton } from "./buttons"
import Dropdown from "./dropdown"

const Header = ({ siteTitle }) => {
  const [isNavToggle, setisNavToggle] = useState("hidden")
  return (
    <header>
      <div className="flex items-center justify-between mx-2 pt-2">
        <span
          onClick={() =>
            isNavToggle === "hidden"
              ? setisNavToggle("block")
              : setisNavToggle("hidden")
          }
          className="flex flex-col"
        >
          <NavIcon isNavToggle={isNavToggle} />
        </span>
        <GithubButton />
      </div>
      <div className={isNavToggle}>
        <Dropdown isNavToggle={isNavToggle} setisNavToggle={setisNavToggle} />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
