import { useStaticQuery, graphql } from "gatsby"

const useCards = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulCards {
        nodes {
          link
          cardText
          icon {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)
  return data.allContentfulCards.nodes.map(card => ({
    link: card.link,
    icon: card.icon.gatsbyImageData,
    cardText: card.cardText,
  }))
}

export default useCards
