import React from "react"
import { Link } from "gatsby"

const LinkStyled = props => {
  return (
    <Link
      className="p-3 hover:dark:border-focus hover:dark:text-focus bg-neutral-04 dark:bg-main hover:scale-105  mr-auto"
      activeClassName="active"
      to={props.to}
    >
      {props.text}
    </Link>
  )
}

const Dropdown = ({ setisNavToggle }) => {
  return (
    <div
      onClick={() => setisNavToggle("hidden")}
      className="block h-screen backdrop-filter backdrop-blur-sm fixed w-screen lg:p-2 z-10"
    >
      <div className="w-full md:max-w-xs p-4 flex flex-col space-y-2 lg:rounded-lg dark:text-primary dark:bg-main bg-neutral-04 shadow-lg">
        <LinkStyled to="/" text="Home" />
        <LinkStyled to="/about" text="About Me" />
        <LinkStyled to="/portfolio" text="Projects" />
        <LinkStyled to="/contact" text="Contact" />
      </div>
    </div>
  )
}

export default Dropdown
