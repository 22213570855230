/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Seo from "./seo"
import Sidebar from "./sidebar"

const Layout = ({ children, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Seo title={page} />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div className="border-transparent w-full">
        <div className="mx-auto flex items-center justify-center mt-8 lg:mt-0 lg:max-w-7xl xl:max-w-screen">
          {page !== "Index" && "Bakery" && (
            <Sidebar className="hidden lg:block" />
          )}
          <main className="min-h-full flex justify-center w-full xl:transform xl:scale-115 lg:items-center 2xl:scale-115">
            {children}
          </main>
        </div>
      </div>
      {/* <footer className="body ml-1 text-xs text-neutral-02 whitespace-nowrap inline">
        © {new Date().getFullYear()}, Built with
        {` `}
        <a className="hover:text-focus" href="https://www.gatsbyjs.com">
          {` `}
          {`GatsbyJS`}
        </a>
        {`, `}
        <a className="hover:text-focus" href="https://tailwindcss.com/">
          {`TailwindCSS`}
        </a>
        {` & `}
        <a className="hover:text-focus" href="https://www.contentful.com">
          {`Contentful`}
        </a>
      </footer> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
