import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import useCards from "../hooks/use-cards"

import { Card } from "./card"

const Sidebar = () => {
  const cards = useCards()

  const data = useStaticQuery(graphql`
    query ImageQuery {
      allContentfulHero {
        nodes {
          heroImage {
            gatsbyImageData(width: 100, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  const { heroImage } = data.allContentfulHero.nodes[0]

  return (
    <div className="min-h-screen w-80 hidden lg:flex lg:flex-col lg:justify-center">
      <div className="flex items-center justify-center">
        <div class="rounded-full ml-2 h-24 w-24 flex items-center justify-center">
          <GatsbyImage image={heroImage.gatsbyImageData} />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-2">
        {cards.map(
          card =>
            card.link === "/about" && (
              <Card
                key={card.id}
                image={card.icon}
                text={card.cardText}
                link={card.link}
              />
            )
        )}
        {cards.map(
          card =>
            card.link === "/portfolio" && (
              <Card
                key={card.id}
                image={card.icon}
                text={card.cardText}
                link={card.link}
              />
            )
        )}
        {cards.map(
          card =>
            card.link === "/contact" && (
              <Card
                key={card.id}
                image={card.icon}
                text={card.cardText}
                link={card.link}
              />
            )
        )}
      </div>
    </div>
  )
}

export default Sidebar
